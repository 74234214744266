<template>
    <div
        class="container"
        :style="{
            'padding-top': GLOBAL_VAR_SAFE_PADDING_TOP,
            'padding-bottom': GLOBAL_VAR_SAFE_PADDING_BOTTOM,
        }"
    >
        <h1 class="text-light mb-3">{{ t('pages.pageNotFound.heading') }}</h1>
        <div class="card shadow-sm p-3">
            <h5 class="">{{ t('pages.pageNotFound.explanation') }}</h5>
            <img src="@/assets/404.jpg" alt="" class="mb-3" />
            <p>
                {{ t('pages.pageNotFound.location') }}
            </p>
            <router-link to="/" class="btn btn-primary">{{
                t('pages.pageNotFound.back')
            }}</router-link>
        </div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const GLOBAL_VAR_SAFE_PADDING_TOP = inject('GLOBAL_VAR_SAFE_PADDING_TOP');
const GLOBAL_VAR_SAFE_PADDING_BOTTOM = inject('GLOBAL_VAR_SAFE_PADDING_BOTTOM');
</script>
